import { createContext, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { CategoryContext } from './CategoryContext';

const BASE_URL = process.env.REACT_APP_BASE_URL;
const STORE_ID = process.env.REACT_APP_STORE_ID;

export const ProductsContext = createContext(null);

export const ProductsProvider = ({ children }) => {
	// PRODUCT LIST STATE
	const [products, setProducts] = useState([]);
	const [selectedSubproduct, setSelectedSubproduct] = useState();
	const [selectedProduct, setSelectedProduct] = useState();
	const { categories } = useContext(CategoryContext);

	// FETCH PRODUCTS LIST FROM API
	useEffect(() => {
		if (categories) {
			const categoriesFilter = { categories: categories.map((category) => ({ slug: category.slug, limit: 15 })) };
			const optionsProducts = {
				method: 'POST',
				headers: { 'Content-Type': 'application/json' },
				body: JSON.stringify(categoriesFilter), // {"slug":"agenda","limit":50}
			};
			fetch(
				`${BASE_URL}/products/list-products-in-home/store/${STORE_ID}/visible/enabled`,
				optionsProducts
			)
				.then((response) => response.json())
				.then((response) => {
					setProducts(response.products)
				})
				.catch((err) => console.error(err));
		}
	}, [categories]);

	// CURSOS
	const cursosID = process.env.REACT_APP_CURSO_ID;
	const productsCursos = products?.filter(
		(produto) => produto.categoryId === cursosID
	);

	// FARDAMENTO
	const fardamentoID = process.env.REACT_APP_FARDAMENTO_ID;
	const productsFardamento = products?.filter(
		(produto) => produto.categoryId === fardamentoID
	);

	// MATERIAL DIDÁTICO
	const materialDidaticoID = process.env.REACT_APP_MATERIAL_DIDATICO_ID;
	const productsMaterial = products?.filter(
		(produto) => produto.categoryId === materialDidaticoID
	);

	// PAPELARIA
	const papelariaID = process.env.REACT_APP_PAPELARIA_ID;
	const productsPapelaria = products?.filter(
		(produto) => produto.categoryId === papelariaID
	);

	// FETCH HIGHLIGHT
	const [productsHighlight, setProductsHighlight] = useState([]);
	useEffect(() => {
		const optionsListHighlight = {
			method: 'GET',
			url: `${BASE_URL}/highlights/list-highlight-by-id/${process.env.REACT_APP_HIGHLIGHT_HOME_ID}/store/${STORE_ID}/visible/enabled`,
		};

		axios
			.request(optionsListHighlight)
			.then(function (response) {
				setProductsHighlight(response.data.highlight);
			})
			.catch(function (error) {
				console.error(error);
			});
	}, []);

	// SEARCH RESULTS STATE
	const [searchResult, setSearchResult] = useState([]);
	const [searchField, setSearchField] = useState('');

	function handleChangeSearch(value) {
		setSearchField(value);
	}

	const navigate = useNavigate();

	// SET SEARCH-MOBILE STATE
	const [isNavExpanded, setIsNavExpanded] = useState(false);

	const navMenuHandler = () => {
		setIsNavExpanded(!isNavExpanded);
	};

	const optionsSearch = {
		method: 'GET',
		url: `${BASE_URL}/products/search-products-by-title/store/${STORE_ID}/visible/enabled`,
		params: { title: `${searchField}` },
	};

	let handleSubmitSearch = (e) => {
		e.preventDefault();
		axios
			.request(optionsSearch)
			.then(function (response) {
				setSearchResult(response.data.product);
				navigate('/busca');
			})
			.catch(function (error) {
				console.error(error);
			});
		setIsNavExpanded(!isNavExpanded);
	};

	const value = {
		products,
		productsCursos,
		productsMaterial,
		productsFardamento,
		productsPapelaria,
		productsHighlight,
		selectedSubproduct,
		setSelectedSubproduct,
		selectedProduct,
		setSelectedProduct,
		searchResult,
		setSearchResult,
		searchField,
		setSearchField,
		handleChangeSearch,
		handleSubmitSearch,
		isNavExpanded,
		setIsNavExpanded,
		navMenuHandler,
		cursosID,
		fardamentoID,
		materialDidaticoID,
		papelariaID,
	};
	return (
		<ProductsContext.Provider value={value}>
			{children}
		</ProductsContext.Provider>
	);
};
